import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid, Heading} from "@chakra-ui/react";

function LanguageSelection(props) {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((currentIndex) => (currentIndex + 1) % props.languages.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [props.languages.length]);

    const selectLanguage = (language) => {
        const currentUrl = new URL(window.location.href);

        let redirectUrl = currentUrl.pathname + language;
        redirectUrl += window.location.search;

        window.location = redirectUrl;
    };

    return (
        <Container height={'100%'}>
            <Box height={'100%'} display='flex' justifyContent={'center'} flexDir={'column'}>
                <Box borderWidth='1px' borderRadius='lg' pb={6}>
                    <Heading textAlign={'center'} mt={6}>
                        {props.languages[currentIndex].headline}
                    </Heading>
                    <Box pl={20} pr={20}>
                        <Grid mt={6} templateColumns='repeat(2, 1fr)' gap={6}>
                            {props.languages.map((language, index) => (
                                <Button onClick={() => selectLanguage(language.iso)} background={'brand'}
                                        color={'white'} width={'100%'} key={index}>
                                    {language.iso.toUpperCase()}
                                </Button>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default LanguageSelection;
