import Step from "../../components/step/Step";
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightAddon,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage, useIntl} from "react-intl";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import NextButton from "../../components/next-button/NextButton";
import {useEffect, useRef} from "react";

function Step4() {
    const intl = useIntl();

    let unit = (intl.locale === 'en-gb' || intl.locale === 'en-ie') ? 'st' : 'kg';

    const UserDataSchema = Yup.object().shape({
        weight: Yup.number()
            .max('300', intl.formatMessage({id: 'form.weight.required'}))
            .required(intl.formatMessage({id: 'form.weight.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
    });

    let navigate = useNavigate();
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (<Step heading={intl.formatMessage({id: 'step4.headline'})}
                  description={intl.formatMessage({id: 'step4.description'})}
                  currentStep={4}
                  backLink={'/step3'}>
            <Box mt={5}>
                <Formik
                    initialValues={{
                        weight: useStore.getState().weight
                    }}
                    validationSchema={UserDataSchema}
                    onSubmit={(values, actions) => {
                        useStore.setState({
                            weight: values.weight
                        });
                        navigate('../step5', {replace: true});
                    }}
                >
                    {(props) => (<Form>
                            <Field name={'weight'}>
                                {({field, form}) => (<FormControl isInvalid={form.errors.weight && form.touched.weight}>
                                        <FormLabel fontWeight={'bold'} htmlFor="weight">
                                            <FormattedMessage id="form.weight.label"/>
                                        </FormLabel>

                                        {unit === 'st' && <NumberInput
                                            min={6}
                                            max={25}
                                            precision={1}
                                            step={0.1}>
                                            <NumberInputField {...field} id="weight" ref={inputRef}/>
                                            <NumberInputStepper>
                                                <NumberIncrementStepper/>
                                                <NumberIncrementStepper/>
                                            </NumberInputStepper>
                                        </NumberInput>}
                                        {unit === 'kg' && <InputGroup>
                                            <Input pattern={'[0-9]*'}
                                                   inputMode={'number'}
                                                   {...field}
                                                   id="weight"
                                                   ref={inputRef}/>

                                            <InputRightAddon pointerEvents="none">
                                                kg
                                            </InputRightAddon>
                                        </InputGroup>}

                                        <FormErrorMessage>{form.errors.weight}</FormErrorMessage>
                                    </FormControl>)}
                            </Field>

                            <NextButton/>
                        </Form>)}
                </Formik>
            </Box>
        </Step>)
}

export default Step4;
