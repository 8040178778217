import {Box} from "@chakra-ui/react";

function GenderCard(props) {
    const { onClick, children } = props;

    return (
        <Box cursor="pointer"
             borderWidth="1px"
             borderRadius="md"
             boxShadow="md"
             color={"white"}
             background={'secondary'}
             _hover={{
                 bg: "brand",
                 color: "white",
                 borderColor: "brand"
             }}
             _checked={{
                 bg: "brand",
                 color: "white",
                 borderColor: "brand"
             }}
             _focus={{
                 boxShadow: "outline",
             }}
             px={3}
             py={3}
             onClick={onClick}>
            {children}
        </Box>
    )
}

export default GenderCard;