import './Step8.scss';
import Step from "../../components/step/Step";
import {useIntl} from "react-intl";
import useStore from "../../storage/DataStore";
import {Badge, Box, Heading, Image, SimpleGrid, Text} from "@chakra-ui/react";
import fitnessCalculator from "fitness-health-calculations";
import moment from "moment/moment";
import Products from "../../components/products/Products";
import React from "react";
import {Reviews} from "../../components/reviews/Reviews";
import CtaButton from "../../components/cta-button/CtaButton";
import FemaleBefore from "../../assets/step8/female-before.webp";
import FemaleAfter from "../../assets/step8/female-after.jpg";
import MaleBefore from "../../assets/step8/male-before.webp";
import MaleAfter from "../../assets/step8/male-after.jpg";

function Step8() {
    const getMonthsArray = (startDate, endDate) => {
        const result = [];

        while (startDate.isSameOrBefore(endDate, 'month')) {
            if (result.length < 4) {
                result.push(startDate.format('MMM'));
            }

            startDate.add(1, 'month');
        }

        return result;
    };

    const intl = useIntl();
    let country = intl.locale;

    let unit = (intl.locale === 'en-gb' || intl.locale === 'en-ie') ? 'st' : 'kg';

    const state = useStore.getState();

    let caloriesDaily = 7000;

    if (unit === 'st') {
        caloriesDaily = 15000;
    }

    let weight = state.weight;
    let targetWeight = state.targetWeight;
    let weightDifference = weight - targetWeight;
    let calcWeight = parseInt(weight);
    let calcWeightDifference = weightDifference

    let tDee = fitnessCalculator.tdee(
        'male',
        18,
        180,
        calcWeight,
        'moderate'
    );

    let estimatedDays = Math.round(calcWeightDifference * caloriesDaily / tDee);
    let estimatedEndDate = moment().add(estimatedDays, 'days');

    const estimatedEndDateFormatted = estimatedEndDate.format('Do MMMM YYYY')
    const months = getMonthsArray(moment(), estimatedEndDate);

    let outgoingLink = intl.formatMessage({id: "outgoingUrl"});

    if (window.location.search !== '') {
        outgoingLink += window.location.search;
    }

    const bodyBefore = state.gender === 'male' ? MaleBefore : FemaleBefore;
    const bodyAfter = state.gender === 'male' ? MaleAfter : FemaleAfter;

    // tracking
    const trackingIframes = [];
    const currentUrl = window.location.toString();
    const urlParams = new URLSearchParams(window.location.search);

    const ts = Math.floor(Date.now() / 1000);

    if (currentUrl.search('/de') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18688&e=6067&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1468');
    }
    if (currentUrl.search('/at') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18689&e=6068&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1460');
    }
    if (currentUrl.search('/ch') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18690&e=6069&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1467');
    }
    if (currentUrl.search('/fr') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18691&e=6070&t=' + ts + '&r=' + urlParams.get('subid2'));
    }
    if (currentUrl.search('/gb') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18692&e=6071&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1474');
    }
    if (currentUrl.search('/ie') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18693&e=6072&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1471');
    }
    if (currentUrl.search('/lude') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18734&e=6076&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1472');
    }
    if (currentUrl.search('/lufr') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18735&e=6077&t=' + ts + '&r=' + urlParams.get('subid2'));
    }
    if (currentUrl.search('/dk') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18736&e=6078&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1469');
    }
    if (currentUrl.search('/fi') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18737&e=6079&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1470');
    }
    if (currentUrl.search('/nl') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18773&e=6090&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1473');
    }
    if (currentUrl.search('/benl') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18774&e=6091&t=' + ts + '&r=' + urlParams.get('subid2'));
        trackingIframes.push('https://quickloadexpress.com/pixel?session_id=' + urlParams.get('subid2') + '&transaction_id=' + ts + '&event_id=E1466');
    }
    if (currentUrl.search('/befr') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframes.push('https://getsomethingquick.com/p.ashx?o=18775&e=6092&t=' + ts + '&r=' + urlParams.get('subid2'));
    }

    return (
        <Step currentStep={8}
              backLink={'/step7'}>

            <Heading fontSize={'md'}
                     fontWeight={'normal'}
                     px={[5, 10]}
                     mb={2}>
                {intl.formatMessage({id: 'step8.headline'})}
            </Heading>

            <Heading px={[5, 10]} fontSize={['2xl', '3xl']} mb={5}>{intl.formatMessage({id: 'step8.subheadline'}, {
                weight: targetWeight,
                date: estimatedEndDateFormatted
            })}</Heading>

            <Box position={'relative'}>
                <Box position={'absolute'} bottom={[35, 50]} right={[3, 50]} id="goal">
                    <Box background={'#eb731a'} color={'white'} p={2} borderRadius={'md'} fontSize={16}>
                        {targetWeight} {unit}
                    </Box>
                </Box>

                <Box position={'absolute'}
                     bottom={[3, 6]}
                     right={[7, 67]}
                     background={'#eb731a'}
                     width={5}
                     height={5}
                     borderRadius={10}
                     borderWidth={3}
                     borderColor={'white'}>
                </Box>

                <svg width="100%" height="100%" viewBox="0 0 360 215" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <g>
                        <path stroke-width="2" stroke="#E0E3EB" d="m1.90317,212.92857"></path>
                        <path stroke="null" fill="#E0E3EB"
                              d="m360,191.06765c-109.96311,5.54168 -173.55631,-31.70359 -250.38226,-96.50748c-34.44129,-33.67016 -72.24027,-79.82148 -109.50695,-79.82148l0,200.2613l359.88921,0l0,-23.93233l0,-0.00001z"
                              opacity="0.3"></path>
                        <path stroke="#498a36" className="weightGraphicPath" stroke-width="3"
                              d="M0.7142999999999802,13.857020000000006C39.37223999999998,13.857020000000006 75.29193999999998,63.15854000000001 109.64096999999998,94.58097000000001C179.17647999999997,158.19135 249.31622999999996,199.24315 358.69744,193.5603"></path>
                        <path stroke="#E0E3EB" d="m0.07794,213.82583l360.066,0" stroke-width="2"></path>
                    </g>
                </svg>
            </Box>

            <SimpleGrid columns={months.length} spacing={10} fontSize={'sm'} mt={2}>
                <Box textAlign={'start'}>
                    Start
                </Box>
            </SimpleGrid>

            <Box border={'1px #e0e3eb solid'} borderRadius={'lg'} mt={[3, 5]} background={'white'}>
                <SimpleGrid className={'before-after'} columns={2} textAlign={'center'}>
                    <Box>
                        <Box p={3}>
                            <Heading fontSize={'lg'} mb={3}>
                                {intl.formatMessage({id: 'step8.beforeAfter.now'})}
                            </Heading>

                            <Image display={'inline-block'} height={150} src={bodyBefore}/>

                            <Text>
                                {intl.formatMessage({id: 'step8.beforeAfter.now'})}: <Text fontWeight={'bold'}
                                                                                           display={'inline'}>{weight} {unit}</Text>
                            </Text>
                        </Box>

                        <Box p={4} background={'#f6f6f6'}>
                            <Text fontSize={'md'}
                                  fontWeight={'bold'}>{intl.formatMessage({id: 'step8.beforeAfter.bodyFat'})}</Text>
                            <Badge>30-45%</Badge>
                        </Box>
                    </Box>
                    <Box>
                        <Box p={3}>
                            <Heading fontSize={'lg'} mb={3} className={'stroke'}>
                                {intl.formatMessage({id: 'step8.beforeAfter.target'})}
                            </Heading>

                            <Image display={'inline-block'} height={150} src={bodyAfter}/>

                            <Text>
                                {intl.formatMessage({id: 'step8.beforeAfter.target'})}: <Text fontWeight={'bold'}
                                                                                              color={'brand'}
                                                                                              display={'inline'}>{targetWeight} {unit}</Text>
                            </Text>
                        </Box>

                        <Box p={4} background={'#f6f6f6'}>
                            <Text fontSize={'md'}
                                  fontWeight={'bold'}>{intl.formatMessage({id: 'step8.beforeAfter.bodyFat'})}</Text>
                            <Badge colorScheme='green'>27-37%</Badge>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Box>

            {/*<Box borderRadius={'lg'} textAlign={'left'} background={'#e6e6e6'} p={[3, 5]} mt={[3, 10]}>*/}
            {/*    <Grid templateColumns={'repeat(5, 1fr)'} alignItems={'center'} gap={[2, 4]}>*/}
            {/*        <GridItem colSpan={1} textAlign={'center'}>*/}
            {/*            <Image src={UniLogo2} display={'inline-block'}/>*/}
            {/*        </GridItem>*/}
            {/*        <GridItem colSpan={4} fontSize={['sm', 'md']}>*/}
            {/*            <FormattedMessage id="step8.studies1.text"*/}
            {/*                              values={{*/}
            {/*                                  b: (chunks) => <strong>{chunks}</strong>*/}
            {/*                              }}/>*/}
            {/*        </GridItem>*/}
            {/*    </Grid>*/}
            {/*    <Grid templateColumns={'repeat(5, 1fr)'} alignItems={'center'} gap={[2, 4]} mt={4}>*/}
            {/*        <GridItem colSpan={1} textAlign={'center'}>*/}
            {/*            <Image height={10} src={MayoLogo} display={'inline-block'}/>*/}
            {/*        </GridItem>*/}
            {/*        <GridItem colSpan={4} fontSize={['sm', 'md']}>*/}
            {/*            <FormattedMessage id="step8.studies2.text"*/}
            {/*                              values={{*/}
            {/*                                  b: (chunks) => <strong>{chunks}</strong>*/}
            {/*                              }}/>*/}
            {/*        </GridItem>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}

            <Heading mt={[3, 10]}>
                {intl.formatMessage({id: 'step8.productRecommendation.headline'})}
            </Heading>

            <Box mt={5}>
                <Products country={country} totalDays={estimatedDays}/>
            </Box>

            <Box textAlign={'center'} mt={5}>
                <CtaButton link={outgoingLink}
                           text={intl.formatMessage({id: 'complete.product.button'})}/>
            </Box>

            <Box textAlign={'left'} mt={5}>
                <Reviews country={country}/>
            </Box>

            <Box textAlign={'center'} mt={10}>
                <CtaButton link={outgoingLink}
                           text={intl.formatMessage({id: 'complete.product.button'})}/>
            </Box>

            {trackingIframes.map((iframeSrc, index) => (
                <iframe key={index} src={iframeSrc} title={`tkr-${index}`} height="1" width="1"
                        frameBorder="0"></iframe>
            ))}
        </Step>
    );
}

export default Step8;
