import {extendTheme} from "@chakra-ui/react"

const ChakraTheme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "#f8f8f8"
            }
        }
    },
    fonts: {
        heading: "Inter, sans-serif",
        body: "Inter, sans-serif",
    },
    input: {
        focusBorderColor: '#498a36'
    },
    colors: {
        secondary: '#498a36',
        secondaryLighter: '#e0e3eb',
        brand: '#88c862',
        orange: '#eb731a',
        cta: '#eb731a'
    },
    components: {
        Input: {
            defaultProps: {
                focusBorderColor: '#abc24b',
            }
        },
        Progress: {
            baseStyle: {
                filledTrack: {
                    bg: '#498a36'
                }
            }
        }
    }
})

export default ChakraTheme;
