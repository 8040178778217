import Step from "../../components/step/Step";
import {
    Box
} from "@chakra-ui/react";
import {useIntl} from "react-intl";
import Image from "./Image.jpg"
import {SuccessStory} from "../../components/success-story/SuccessStory";
import NextButton from "../../components/next-button/NextButton";

function SuccessStory1() {
    const intl = useIntl();

    return (<Step hideSteps={true}
                  backLink={'/step2'}>
        <Box>
            <SuccessStory title={intl.formatMessage({id: 'success-story1.headline'})}
                          description={intl.formatMessage({id: 'success-story1.text'})}
                          image={Image}/>

            <NextButton redirect={'step3'}/>
        </Box>
    </Step>)
}

export default SuccessStory1;