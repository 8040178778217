import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/da';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import English from './lang/en.json';
import French from './lang/fr.json';
import Finish from './lang/fi.json';
import Danish from './lang/da.json';
import Dutch from './lang/nl.json';

import ChakraTheme from "./templates/Chakra";
import TheanexTemplate from "./templates/Theanex";

import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Step7 from "./pages/step7/Step7";
import Step8 from "./pages/step8/Step8";
import Loader from "./pages/loader/Loader";
import SuccessStory1 from "./pages/success-story1/SuccessStory1";

import genderMale from './assets/step1/male.svg';
import genderFemale from './assets/step1/female.svg';
import maleBody1 from './assets/step7/bodytype-male-1.svg';
import maleBody2 from './assets/step7/bodytype-male-2.svg';
import maleBody3 from './assets/step7/bodytype-male-3.svg';
import femaleBody1 from './assets/step7/bodytype-female-1.svg';
import femaleBody2 from './assets/step7/bodytype-female-2.svg';
import femaleBody3 from './assets/step7/bodytype-female-3.svg';
import graphImage from './assets/step6/graph.svg';

import useImagePreloader from "./hooks/UseImagePreloader";
import LanguageSelection from "./pages/language-selection/LanguageSelection";

function App() {
    const currentUrl = window.location.toString();

    const preloadSrcList = [
        genderMale,
        genderFemale,
        maleBody1,
        maleBody2,
        maleBody3,
        femaleBody1,
        femaleBody2,
        femaleBody3,
        graphImage
    ];

    useImagePreloader(preloadSrcList)

    let messages = German;
    let locale = 'de';
    moment.locale('de');

    switch (true) {
        case (currentUrl.search('/at') !== -1):
            messages.outgoingUrl = 'https://buytheanex.com/at/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Wien';
            messages['step10.headline'] = 'Svenja aus Graz';
            break;

        case (currentUrl.search('/ch') !== -1):
            messages.outgoingUrl = 'https://theanex-shop.com/ch/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Basel';
            messages['step10.headline'] = 'Svenja aus Zürich';
            locale = 'de-ch';
            break;

        case (currentUrl.search('/fr') !== -1):
            messages = French;
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/gb') !== -1):
            messages = English;
            locale = 'en-gb';
            moment.locale('en');
            break;

        case (currentUrl.search('/nl') !== -1):
            messages = Dutch;
            locale = 'nl';
            moment.locale('nl');
            break;

        case (currentUrl.search('/benl') !== -1):
            messages = Dutch;
            messages['success-story1.text'] = 'Het gebruik van Theanex capsules® is veilig en effectief. Geen wonder dat Theanex capsules® in 2024 de best verkochte vetverbrander in België zullen zijn.';
            messages['outgoingUrl'] = 'https://buytheanex.com/benl/nu-kopen.html';
            locale = 'nl';
            moment.locale('nl');
            break;

        case (currentUrl.search('/befr') !== -1):
            messages = French;
            messages['success-story1.text'] = 'L’utilisation des capsules Theanex® est sûre et efficace. Pas étonnant que les capsules Theanex® soient le brûleur de graisse le plus vendu en Belgique en 2024.';
            messages['outgoingUrl'] = 'https://buytheanex.com/befr/jetzt-kaufen.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/fi') !== -1):
            messages = Finish;
            locale = 'fi';
            moment.locale('fi');
            break;

        case (currentUrl.search('/dk') !== -1):
            messages = Danish;
            locale = 'da';
            moment.locale('da');
            break;

        case (currentUrl.search('/ie') !== -1):
            messages = English;
            messages.outgoingUrl = 'https://buytheanex.com/ie/buy-now.html';
            locale = 'en-ie';
            moment.locale('en');
            break;

        case (currentUrl.search('/lude') !== -1):
            messages.outgoingUrl = 'https://buytheanex.com/lude/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/lufr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://buytheanex.com/lufr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        default:
    }

    document.title = messages.title + ' | Theanex Kapseln®';

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <TheanexTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2/>
                            }/>
                            <Route path='/step3' element={
                                <Step3/>
                            }/>
                            <Route path='/step4' element={
                                <Step4/>
                            }/>
                            <Route path='/step5' element={
                                <Step5/>
                            }/>
                            <Route path='/step6' element={
                                <Step6/>
                            }/>
                            <Route path='/step7' element={
                                <Step7/>
                            }/>
                            <Route path='/step8' element={
                                <Step8/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/success-story1' element={
                                <SuccessStory1/>
                            }/>
                            <Route path='/lu' element={
                                <LanguageSelection languages={[{iso: 'de', headline: 'Wähle deine Sprache'}, {iso: 'fr', headline: 'Choisissez votre langue'}]}/>
                            }/>
                            <Route path='/be' element={
                                <LanguageSelection languages={[{iso: 'nl', headline: 'Kies je taal'}, {iso: 'fr', headline: 'Choisissez votre langue'}]}/>
                            }/>
                        </Routes>
                    </TheanexTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
