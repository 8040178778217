import Logo from '../../assets/home/logo.svg';
import {Box, Container, Heading, Image} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import AnswerOption from "../../components/answer-option/AnswerOption";

function Home() {
    let navigate = useNavigate();
    const intl = useIntl();
    const nextStep = function () {
        navigate('/step1', {replace: true});
    }

    if (window.location.pathname === '/lu') {
        window.setTimeout(() => {
            navigate('/lu', {replace: true});
        }, 1);
    }
    if (window.location.pathname === '/be') {
        window.setTimeout(() => {
            navigate('/be', {replace: true});
        }, 1);
    }

    return (
        <Container>
            <Box textAlign={"center"}>
                <Box textAlign={'center'} mt={5} pb={2}>
                    <Image height={8} src={Logo} alt={'Theanex Kapseln®'} className="logo" display={'inline-block'}/>
                </Box>

                <Heading mb={[0, 3]} fontSize={[22, 32]}>
                    <FormattedMessage id="home.heading"/>

                    <Box mt={5}>
                        <AnswerOption onClick={() => nextStep()}>
                            {intl.formatMessage({id: 'home.option1'})}
                        </AnswerOption>
                        <AnswerOption onClick={() => nextStep()}>
                            {intl.formatMessage({id: 'home.option2'})}
                        </AnswerOption>
                        <AnswerOption onClick={() => nextStep()}>
                            {intl.formatMessage({id: 'home.option3'})}
                        </AnswerOption>
                        <AnswerOption onClick={() => nextStep()}>
                            {intl.formatMessage({id: 'home.option4'})}
                        </AnswerOption>
                        <AnswerOption onClick={() => nextStep()}>
                            {intl.formatMessage({id: 'home.option5'})}
                        </AnswerOption>
                    </Box>
                </Heading>
            </Box>
        </Container>
    );
}

export default Home;
