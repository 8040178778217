import { Button } from "@chakra-ui/react";

function AnswerOption(props) {
    const { onClick, children } = props;

    return (
        <Button width={"100%"}
                mb={3}
                p={8}
                color={"white"}
                background={"secondary"}
                _hover={{
                    bg: "brand",
                    borderColor: "brand"
                }}
                onClick={onClick}>
            {children}
        </Button>
    );
}

export default AnswerOption;
