import Step from "../../components/step/Step";
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightAddon,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import useStore from "../../storage/DataStore";
import NextButton from "../../components/next-button/NextButton";
import {useEffect, useRef} from "react";

function Step5() {
    const intl = useIntl();

    let unit = (intl.locale === 'en-gb' || intl.locale === 'en-ie') ? 'st' : 'kg';

    const UserDataSchema = Yup.object().shape({
        targetWeight: Yup.number()
            .max('300', intl.formatMessage({id: 'form.targetWeight.required'}))
            .required(intl.formatMessage({id: 'form.targetWeight.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
    });

    let navigate = useNavigate();
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (<Step heading={intl.formatMessage({id: 'step5.headline'})}
                  currentStep={5}
                  backLink={'/step4'}>
        <Box mt={5}>
            <Formik
                initialValues={{
                    targetWeight: useStore.getState().targetWeight
                }}
                validationSchema={UserDataSchema}
                onSubmit={(values, actions) => {
                    useStore.setState({
                        targetWeight: values.targetWeight
                    });
                    navigate('../step6', {replace: true});
                }}
            >
                {(props) => (<Form>
                    <Field name={'targetWeight'}>
                        {({field, form}) => (
                            <FormControl isInvalid={form.errors.targetWeight && form.touched.targetWeight}>
                                <FormLabel fontWeight={'bold'} htmlFor="targetWeight">
                                    <FormattedMessage id="form.targetWeight.label"/>
                                </FormLabel>

                                {unit === 'st' && <NumberInput min={6}
                                                               max={25}
                                                               precision={1}
                                                               step={0.1}>
                                    <NumberInputField {...field} id="targetWeight" ref={inputRef}/>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper/>
                                        <NumberIncrementStepper/>
                                    </NumberInputStepper>
                                </NumberInput>}

                                {unit === 'kg' && <InputGroup>
                                    <Input pattern={'[0-9]*'}
                                           inputMode={'number'}
                                           {...field}
                                           id="targetWeight"
                                           ref={inputRef}/>

                                    <InputRightAddon pointerEvents="none">
                                        kg
                                    </InputRightAddon>
                                </InputGroup>}

                                <FormErrorMessage>{form.errors.targetWeight}</FormErrorMessage>
                            </FormControl>)}
                    </Field>

                    <NextButton/>
                </Form>)}
            </Formik>
        </Box>
    </Step>)
}

export default Step5;